
import { Container } from '@mui/system';
import { EXTRA_FLAT_FORM } from 'api/extraFlatForm';
import { Alert, EmptyRes, XButton } from 'components/Layout';
import PaymentMethod from 'components/PaymentMethod';
import icon from 'constants/icon';
import { PLF_TYPE } from 'constants/plat-form';
import { AppContext } from 'context/AppProvider';
import HeadMobile from 'features/HeadMobile';
import HeadTitle from 'features/HeadTitle';
import { useCartReducer, useDeviceMobile } from 'hooks';
import { ICart, ICartGroupOrg } from 'interface';
import IStore from 'interface/IStore';
import UserPaymentInfo from 'pages/Account/components/UserPaymentInfo';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clst, unique } from 'utils';
import style from './cart.module.css'
import { CartCalc, CartOrgItem } from './components';
import { MOMO, VIETTELPAY } from 'common';
import { clearByCheck } from 'redux/cart';

interface ItemType { id: number, quantity: number }

export interface PostOrderType {
  products?: ItemType[],
  services?: ItemType[],
  prepay_cards?: ItemType[],
  treatment_combo?: ItemType[],
  payment_method_id?: number,
  coupon_code?: string[],
  branch_id?: number | null,
  description?: string,
  user_address_id?: number | null,
  payment_method_second_id?: number,
  point?: number
}

function Cart() {
  const { t } = useContext(AppContext) as any
  const IS_MB = useDeviceMobile()
  const platForm = EXTRA_FLAT_FORM()
  const dispatch = useDispatch()
  const { cartList } = useSelector((state: IStore) => state.carts)
  const orgs_id = unique(cartList?.map((i: ICart) => i.org_id))
  const cartGroupOrg: ICartGroupOrg[] = orgs_id?.map(org_id => {
    const cartItemsOrg = cartList?.filter((i: ICart) => i.org_id === org_id)
    return {
      org_id: org_id,
      org: cartItemsOrg[0]?.org,
      cartItemsOrg: cartItemsOrg
    }
  })
  const { cart_confirm, products } = useCartReducer()
  const orgChoose = cart_confirm[0]?.org

  const instancePaymentMethodId = () => {
    let id = MOMO.id
    if (platForm === PLF_TYPE.VIETTEL) {
      id = VIETTELPAY.id
    }
    return id
  }

  const [order, setOrder] = useState<PostOrderType>({
    user_address_id: null,
    branch_id: null,
    payment_method_id: instancePaymentMethodId()
  })
  const removeItemByCheck = () => {
    if (cart_confirm.length > 0) {
      Alert.open({
        message: "Bạn có muốn xóa khỏi giỏ hàng không?",
        actions: [
          { text: 'Hủy', onPress: () => { } },
          { text: 'Xác nhận', onPress: () => dispatch(clearByCheck()), type:'cancel' }
        ]
      })
    }
  }

  return (
    <>
      <HeadTitle title='Giỏ hàng' />
      {IS_MB && <HeadMobile
        title='Giỏ hàng'
        element={
          <XButton
            title={`Xóa (${cart_confirm.length})`}
            className={style.left_head_ctl_item_btn}
            onClick={removeItemByCheck}
          />
        }
      />}
      {
        cartList?.length === 0 ?
          <EmptyRes title='Không có Dịch vụ/Sản phẩm trong giỏ hàng !' isRecommend={true} />
          :
          <Container>
            <div className={style.container}>
              <div className={style.left}>
                <div className={style.left_head}>
                  <div className={style.left_head_label}>{t('pm.product_service')}</div>
                  <div className={style.left_head_ctl}>
                    <span className={style.left_head_ctl_item}>{t('cart.unit_price')}</span>
                    <span className={style.left_head_ctl_item}>{t('pr.quantity')}</span>
                    <span className={style.left_head_ctl_item}>
                      {t('cart.into_money')}
                      <XButton
                        icon={icon.trash}
                        className={style.left_head_ctl_item_btn}
                        onClick={removeItemByCheck}
                      />
                    </span>
                  </div>
                </div>
                <div className={style.left_table}>
                  {
                    cartGroupOrg?.map(itemOrg => (
                      <CartOrgItem
                        orgChoose={orgChoose}
                        key={itemOrg.org_id}
                        itemOrg={itemOrg}
                        cart_confirm={cart_confirm}
                        branch_id={order.branch_id}
                        onChangeBranch={(id: null | number) => setOrder({ ...order, branch_id: id })}
                      />
                    ))
                  }
                </div>
              </div>
              <div className={style.right}>
                <div className={style.right_section}>
                  <UserPaymentInfo
                    disableAddress={products.length === 0}
                  />
                </div>
                <div
                  // style={platForm === PLF_TYPE.BEAUTYX ? {} : {
                  //   display: 'none'
                  // }}
                  className={style.right_section}
                >
                  <PaymentMethod
                    onSetPaymentMethod={(method) =>
                      setOrder({ ...order, payment_method_id: method.id })
                    }
                  />
                </div>
                <div className={clst([style.right_section, style.bottom])}>
                  <CartCalc
                    setOrder={setOrder}
                    orgChoose={orgChoose}
                    order={order}
                  />
                </div>
              </div>
            </div>
          </Container>
      }
    </>
  );
}

export default Cart;
